.logo2 {
  width: 100px;
  height: 80px;
  color: #ffff;
  vertical-align: middle; 
  margin-top: 0px; 
}


li {
text-decoration: none;
color:none;
list-style: none;

}
.nav-bar{
  color: #EBECED;
  background-color: #787982;
  display: flex;
  justify-content: space-between;
  position: fixed;
  position: -webkit-sticky;
  width:100%;
  height: 5rem;
  flex-shrink: 01;
  flex-direction: column;
  top: 2;
  padding-bottom: 0px;
  border-bottom: 1px  #fefefe solid;
  z-index: 999;
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 3em;
  letter-spacing: 4px;
}

.nav-links {
    display: flex;
    justify-content: space-evenly;
    vertical-align: text-top;
   
 
    margin-top: -3.5rem;
    margin-left: 30%;
    margin-right: 3rem;
}
.nav-item {
  position: absolute;
  list-style: none;
  cursor: pointer;
  text-decoration: none;
  font-size: .75rem;
  font-weight: 550;
  padding-right: 1px;
  margin-left: 5px;
  margin-right: 5px;
  text-transform: uppercase;
  }
 
  .nav-item.b-line {
      display: inline-block;
      position: relative;
      color: #eaeef0;
   
    }
  
    .nav-item.b-line:after {
  content: '';
  position: absolute;
  width:100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -10px;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
    }

  
    .nav-item.b-line:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    .toggleNav{
      color:rgb(229, 243, 243);
      font-size: 20px;
      visibility: hidden;
      }

      .nav-bar-toggle {
        visibility: hidden;
      }

 @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .nav-bar {
    position: fixed;
    position: -webkit-sticky;
    width:100%;
    height: 5rem;
    left:0;
    
  } 
  
  .nav-links {
 
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
    margin-left: -10px;
     
}
.nav-links li {
  margin-top: 10px;
  padding-top: 30px;
}
       
    
.logo2 {
  width: 60px;
  height: 37px;
  color: #ffff;
  vertical-align: middle; 
  margin-top: 2px; 
  margin-left: -15px;
}

 * {
     box-sizing: border-box;
      margin: 0 0 0 .5rem;
      padding: 0;
      font-family: 'PT Sans', sans-serif;
      font-size: .8rem;
      }
         }