

.work-container{

   display: grid;
   grid-template-columns: repeat(auto-fit,300px);
   grid-column: 1 /-1;
   grid-template-rows: repeat(1, 1fr);
   place-content: center;
   gap: 6rem;
   margin-top: 1.5rem;
   margin-bottom: 6rem;
}

.card{
    border: 2px solid  #334355; 
    border-radius: 2px;
    background-color:transparent;
    /* box-shadow: 0 5px 10px -3px rgba(#000000);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; */
}
.icon {
    text-align: center;
    width: 100%;
}
   
.one, 
.two, 
.three {
margin-top: 12px;
}
/* .card-text  {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
} */
 /* {
        
    } */
    article {
        padding: 1em;
    }


.profile--container{
   
    margin-top: 3em;
    display: grid;
    
    grid-template-columns: repeat(3, 250px);
    /* //or auto-fit */
    grid-auto-rows: auto;
    gap: 1rem;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
.work-container {
   
        margin-top: 5%;
}
}