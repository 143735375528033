.footer-container {
    background-color: #334355;
    padding: 3rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  
  }
  .footer-container h4 {
    margin-top: 10px;
    padding-bottom: 25px;
  }
  