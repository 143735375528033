
  .h1 {
    margin-top: 80px;
    color: white;
    font-size: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
  }
  
  .form {
    max-width: 250px;
    margin: 0 auto;
    
  }

  .formInfo {
      display: inline-grid;
     
     grid-column: 2 / span3 ;
    max-width: 40%;
    margin-left: 170px;
  } 
    
  .subMit {
    max-width: 40%;
    margin-left: 155px;
    margin-bottom: 50px;
    
  }

  .me {
      padding-top: 2em;
      padding-bottom:1em;
      grid-column: 2 /3;
      text-align: center;
  }
  .p {
    color: #bf1650;
    text-align: center;
  }
  
  input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  label,
  .label,
  section > label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: white;
    font-size: 14px;
    font-weight: 200;
  }
  
  input[type="submit"],
  .button {
    background:  #334355;;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
    display: block;
    appearance: none;
    border-radius: 4px;
    width: 100%;
  }
  
  .buttonBlack {
    background: black;
    border: 1px solid white;
  }


  
  .container-contact {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column: 1 /-1;
    grid-gap: 10px;
    background: url('../assets/berlin.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
     -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;  
   opacity: 8.5;
  
  }
  
  .counter {
    font-weight: 400;
    background: white;
    color: black;
    padding: 10px 15px;
    border-radius: 4px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999999;
    border: 1px solid rgb(79, 98, 148);
    box-shadow: 0 0 4px rgb(79, 98, 148);
  }
  
  button[type="button"] {
    padding: 5px;
    background: #516391;
    color: white;
    letter-spacing: 0px;
    text-transform: none;
    padding: 10px;
    letter-spacing: 2px;
  }
  
  button[type="button"]:hover {
    background: #bf1650;
    color: white;
  }
  
  .pexel-1 {
 text-align: center;
    width: 100%; 
    margin-bottom: 2rem;
    grid-column: 4/4;
  }
  
  @media only screen and (max-width: 1100px) and (min-width: 800px)  {
    input[type="submit"]
    .button {
      font-size: 12px;
      margin-left: 0px;
      padding-right: 50px;
   
    } 


    
  }


  
  @media only screen and (max-width: 6800px) and (min-width: 700px) {
    .subMit {
      max-width: 75%;
    margin-left: 10px;
    margin-bottom: 50px;
    font-size: 10px;
    }
  }
  @media only screen and (max-width: 699px) and (min-width: 500px) {
    .subMitt {
     max-width: 75%;
    margin-left: 10px;
    margin-bottom: 50px;
    font-size: 10px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .formInfo {
       
            margin-left: -2%; 
             margin-top: 8%;   
             max-width: 85%; 
    }

    .me {
    display: none;
    }

    .subMit {
      max-width: 95%;
      margin-left: 8px;
      margin-bottom: 50px;
      font-size: 10px;
      }
   /* .subMit
    .button {
      max-width: 60%;
      
      margin-bottom: 50px;
      padding:  5px;
      font-size: 11px;
      letter-spacing: none;
    } */
  }

  @media only screen and (min-device-width: 500px) and (max-device-width: 660px)  {
    .formInfo {
       
      margin-left: -15%; 
       margin-top: 8%;   
       max-width: 85%; 
}
  }