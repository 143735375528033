* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  font-size: 1rem;
}

body {
  background: #9da2a7;
  color: #EBECED;


}
/* html, body {
  width: 100%; 
} */

h1,h2,h3 { font-weight: 300; }
p { line-height: 30px; }
hr {
  border-color: #f2f2f2;
  width: 100px;
  margin-bottom: 40px;
}

.App {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.btn {
  background: transparent;
  color: #ffffff;
  border: 1px solid #334355;
  border-radius: 4px;
  font-size: .75rem;;
  font-weight: 500;
  letter-spacing: 10px;

  padding:0.9em 1em;
  cursor: pointer;
  -webkit-transition: all 0.6s ease-in;
  transition: all 0.6s ease-in;
  border-color: 150ms ease-out;
  transform: 150ms ease-out;
  text-transform: uppercase;
}

/* Media Queries */
